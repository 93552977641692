import React, { useState, useEffect } from "react"
import { Container, Section } from "../../anti/grid/grid"
import { Card } from "../../anti/card/card"
import { Link } from "../../anti/link/link"
import { useScrollAnim } from "src/components/hooks/hooks"

export const TheTeamMembers = ({ data, lang }) => {
  const block = data
  const [teamsLength, setTeamsLength] = useState("")
  const [trigger, anim] = useScrollAnim()

  useEffect(() => {
    if (block.length > 2) {
      setTeamsLength(true)
    }
  }, [block.length])
  return (
    <Section forwardRef={trigger} className="the-team-members">
      <Container>
        <div className="row">
          {block.map((data, i) => {
            const dataLang = lang === "EN" ? data : data?.translation
            const team = dataLang?.team?.header
            return (
              <div className={teamsLength ? "col-md-4" : "col-md-6"} key={i}>
                <Link to={`/the-team/${data?.slug}`}>
                  <Card
                    variant="overlay"
                    img={team?.image?.sourceUrl}
                    imgRatio={teamsLength ? "r-3-4" : "r-1-1"}
                    title={team?.name}
                    text={team?.jobTitle}
                    className={`${anim(2, "revealBlockInRight")}`}
                  />
                </Link>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}
