import React from "react"

import { Cover } from "../../anti/cover/cover"
import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"
import { useScrollAnim } from "src/components/hooks/hooks"

export const TheTeamCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  const block = data
  return (
    <div ref={trigger}>
      <Cover
        breadcrumb={<Breadcrumb path={"/the-team"} />}
        variant="basic"
        theme="dark"
        img={block.image && block.image.sourceUrl}
        className="the-team-cover"
        imgHeight="h-500px h-md-600px w-100"
        contentMaxWidth="w-md-500px"
        buttons={block?.buttons}
        label={block?.label}
        labelClassName={`${anim(1)}`}
        title={block?.title}
        titleClassName={`${anim(2)}`}
        text={block?.text}
        textClassName={`${anim(3)}`}
      />
    </div>
  )
}
