import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import { TheTeamCover } from "../components/shift/the-team/cover"
import { TheTeamMembers } from "../components/shift/the-team/members"
import { AboutRequest } from "components/shift/about/request"

//Load Context
import { LangContext } from "../context/lang-context"

const TheTeamPage = () => {
  const { lang } = useContext(LangContext)

  const data = useStaticQuery(GET_OUR_TEAM_DETAILS)
  const block =
    lang === "EN"
      ? data?.wordPress?.ourTeamPage?.ourTeamPage
      : data?.wordPress?.ourTeamPage?.translation?.ourTeamPage ||
        data?.wordPress?.ourTeamPage?.ourTeamPage

  const request =
    lang === "EN"
      ? data?.wordPress?.aboutPage?.aboutPage
      : data?.wordPress?.aboutPage?.translation?.aboutPage ||
        data?.wordPress?.aboutPage?.aboutPage

  const teams = data?.wordPress?.teams?.nodes

  return (
    <Layout>
      <Seo title="The Team" />
      <TheTeamCover data={block.cover} />
      <TheTeamMembers data={teams} lang={lang} />
      <AboutRequest data={request.request} />
    </Layout>
  )
}

export default TheTeamPage

const GET_OUR_TEAM_DETAILS = graphql`
  query MyQuery {
    wordPress {
      ourTeamPage: page(id: "our-team", idType: URI) {
        ourTeamPage {
          cover {
            label
            text
            title
            image {
              sourceUrl
            }
          }
        }
        translation(language: ID) {
          ourTeamPage {
            cover {
              label
              text
              title
              image {
                sourceUrl
              }
            }
          }
        }
      }
      aboutPage: page(id: "about", idType: URI) {
        aboutPage {
          request {
            title
            text
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            request {
              title
              text
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
          }
        }
      }
      teams(first: 100, where: { language: EN }) {
        nodes {
          team {
            header {
              name
              jobTitle
              image {
                sourceUrl
              }
            }
          }
          translation(language: ID) {
            team {
              header {
                name
                jobTitle
                image {
                  sourceUrl
                }
              }
            }
          }
          slug
        }
      }
    }
  }
`
